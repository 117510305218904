import { useEffect, useRef, useState } from "react"
import gateway from "../../api"
import SearchResultItem from "../Molecules/SearchResultItem"
import { blackDisconnected, BlackMaginifier, blackNotFound, BlackShoppingBag, blackUserOutlined, WhiteMaginifier, WhiteShoppingBag, whiteUserOutlined } from "../../icons-base64/icons"
import axios from "axios"
import { useLocation } from "react-router-dom"
export default function Search() {
    const [searchResults, setSearchResults] = useState(null)
    const [searchPhrase, setSearchPhrase] = useState("")
    const [searchError, setSearchError] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)

    const searchRef = useRef(null);

    const location = useLocation();

    const isHomePage = location.pathname === '/';
    const isAboutUsPage = location.pathname === '/AboutUs';
    const isContactUsPage = location.pathname === '/ContactUs';
    const isPoliciesPage = location.pathname === '/Policies';
    const isPostPage = location.pathname === '/PostDetail';



    var isBannerPage = false;

    if (isPoliciesPage || isContactUsPage || isAboutUsPage || isHomePage || isPostPage) {
        isBannerPage = true
    }

    const fetchSearchResults = (query) => {

        setSearchLoading(true)
        axios
            .get(`${gateway()}/api/searchPostsAndProducts/?q=${query}`)
            .then(res => {

                setSearchLoading(false)
                setSearchResults(res.data);
            })
            .catch(err => {
                setSearchLoading(true);
                setSearchError(true)
                console.error("search results error : ", err);
            });
    };

    const handleClickOutside = (event) => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setSearchPhrase(""); // Clear the search phrase
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {

        setSearchError(false)
        if (searchPhrase.length > 1) {
            fetchSearchResults(searchPhrase)
        }
    }, [searchPhrase]);


    return <>
        <div className="relative  lg:min-w-[250px]" ref={searchRef}>
            <div className={`flex rounded-xl border-2 ${isBannerPage ? "border-white" : "border-white lg:border-black"} items-center py-[1px]`}>
                <div className=" px-2">
                    <img src={isBannerPage ? WhiteMaginifier : BlackMaginifier} width="24" className="hidden lg:inline-block" />
                    <img src={WhiteMaginifier} width="24" className="lg:hidden" />

                </div>
                <div className=" w-full px-2 ">
                    <input type="text" className={`w-full bg-transparent text-white ${isBannerPage ? "lg:text-white" : "lg:text-black"} text-xs`} onChange={(SP) => setSearchPhrase(SP.target.value)} value={searchPhrase} />
                </div>
            </div>
            {searchPhrase.length > 1 ? <div className={`h-[300px] overflow-y-auto bg-white rounded-xl shadow-lg border-2
                         border-solid ${isBannerPage ? "" : ""}  w-full absolute top-[35px] py-4 px-2 right-0`}>
                {searchLoading ? <div className="w-full h-full flex items-center justify-center">

                    <img src={"/images/loading.svg"} width={60} />

                </div> : <>
                    {searchError ? <div className="flex items-center justify-center h-full w-full text-xs">
                        <img width={35} src={blackDisconnected} className="px-1" /> خطا در دریافت اطلاعات </div> :

                        <>
                            <div className="h-full w-full">{searchResults?.digital_products.length === 0 && searchResults?.posts.length === 0 ?
                                <div className="flex items-center justify-center h-full w-full text-xs">
                                    <img width={30} src={blackNotFound} className="px-1" /> موردی یافت نشد </div> :
                                <div className="flex-col ">
                                    {searchResults?.digital_products.length > 0 ? <><div className="text-xs flex">
                                        <div className="font-bold px-1">' {searchPhrase} '</div> در محصولات
                                    </div>
                                        <div className="flex-col mt-2 mb-4">
                                            {searchResults?.digital_products.map((p) => {
                                                return <a className="" href={`/ProductDetail?${p.id}`}><SearchResultItem pic={`${gateway()}${p.thumbnail}`} title={p.title} /></a>

                                            })}
                                        </div>
                                    </> : null}
                                    {searchResults?.posts.length > 0 ? <><div className="text-xs flex">
                                        <div className="font-bold px-1">' {searchPhrase} '</div> در مقالات
                                    </div>
                                        <div className="flex-col mt-2">
                                            {searchResults?.posts.map((p) => {
                                                return <a className="" href={`/PostDetail?${p.id}`}><SearchResultItem pic={`${gateway()}${p.thumbnail}`} title={p.title} /></a>

                                            })}
                                        </div>
                                    </> : null}

                                </div>
                            }
                            </div>
                        </>}

                </>}
            </div> : null}

        </div>
    </>
}