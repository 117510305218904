import { useState } from "react";
import { DarkGrayMining, grayCategory, DarkGrayElectric, DarkGrayMetal, DarkGraySelolose, DarkGrayConstruction, DarkGrayMicroscope, DarkGrayPetro, DarkEnergy, darkOutlinedAgreeCulture, darkEggs, darkFood, darkIndustry, darkGreyTech, darkGreyTourism } from "../../icons-base64/icons";

export default function CategoryShortCuts() {
    const [categories, setCategories] = useState([
        {
            name: "معدن",
            icon: DarkGrayMining,
            id: 10
        },
        {
            name: "صنایع الکتریکی",
            icon: DarkGrayElectric,
            id: 11
        },
        {
            name: "فلزات اساسی",
            icon: DarkGrayMetal,
            id: 12
        },
        {
            name: "صنایع سلولزی",
            icon: DarkGraySelolose,
            id: 13
        },
        {
            name: "ساختمان، مسکن و شهرسازی",
            icon: DarkGrayConstruction,
            id: 14
        },
        {
            name: "صنایع شیمیائی، داروئی و بهداشتی",
            icon: DarkGrayMicroscope,
            id: 15
        },
        {
            name: "صنایع پتروشیمی",
            icon: DarkGrayPetro,
            id: 16
        },
        {
            name: "سوخت و انرژی",
            icon: DarkEnergy,
            id: 17
        },
        {
            name: "کشاورزی و گلخانه",
            icon: darkOutlinedAgreeCulture,
            id: 18
        },

        {
            name: "صنایع غذایی",
            icon: darkFood,
            id: 19
        },
        {
            name: "مصنوعات صنعتی",
            icon: darkIndustry,
            id: 20
        },
        {
            name: "دام، طیور و آبزیان",
            icon: darkEggs,
            id: 21
        },
        {
            name: "تکنولوژی",
            icon: darkGreyTech,
            id: 26
        },
        {
            name: "خدمات و گردشگری",
            icon: darkGreyTourism,
            id: 27
        },

    ]);

    return (
        <div className="flex justify-center w-full pb-10 pt-10 px-3">
            <div className="flex flex-wrap justify-center gap-4 max-w-6xl py-2">
                {categories && categories.length > 0 ? (
                    categories.map((c, index) => (

                        <div
                            key={index}
                            className="w-[calc(33%-1rem)] lg:w-[calc(12.5%-1rem)] shadow-[0px_0px_6px_0px_rgba(0,_0,_0,_0.1)] p-1 rounded-md text-xs h-[90px] bg-white"
                        >
                            <a href={`/ProductsCategoryDetail?${c.id}`}>
                                <div className="flex flex-col items-center ">
                                    <img src={c.icon} width={27} className="" />
                                    <div className=" flex items-center justify-center h-full text-gray-700 mt-1" >
                                        {c.name}
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
        </div >
    );
}
