import { useState } from "react";
import { useToast } from "../../Context/ToastContext";
import axios from "axios";
import gateway from "../../api";

export default function RequestResetPassFormORG() {
    const [email, setEmail] = useState("");
    const showToast = useToast();

    async function requestReset() {
        try {
            // Send password reset request without CSRF token
            await axios.post(
                `${gateway()}/api/auth/password-reset/`,
                { email: email },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            showToast("ایمیل بازیابی رمز عبور ارسال شد", "", 3000, "success");
        } catch (error) {
            debugger
            console.error("Error requesting password reset:", error);
            if (error && error.response && error.response.data && error.response.data.email && error.response.data.email.length > 0 && error.response.data.email[0] === 'User with this email does not exist.') {
                showToast("کاربری با این ایمیل یافت نشد", "", 3000, "error");
            } else {
                showToast("خطایی رخ داده است. لطفا دوباره تلاش کنید", "", 3000, "error");
            }

        }
    }

    return (
        <div className="h-fit">
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="ایمیل"
                className="text-xs lg:text-base text-gray-600 border rounded-md py-1 px-2 w-full mb-5"
            />
            <div className="text-xs text-gray-600 text-right">
                <div className="mb-1">
                    - لطفا ایمیلی که هنگام ثبت نام استفاده کردید را وارد کرده و بر روی ارسال ایمیل کلیک کنید
                </div>
                <div className="mb-1">
                    - در صورتی که ایمیلی دریافت نکردید لطفا پوشه spam را نیز بررسی کنید.
                </div>
            </div>
            <div className="md:mt-10 mb-4">
                <button
                    className="w-full bg-[#00dfc0] rounded-md p-2 shadow-md border-[#009c86] border border-solid text-white"
                    onClick={requestReset}
                >
                    ارسال ایمیل
                </button>
            </div>
        </div>
    );
}