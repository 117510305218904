import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay'
import { useEffect, useState } from 'react';
import axios from "axios";
import gateway from '../../api';
import PostsListItem from '../Molecules/PostsListItem';
export default function PostsSwiper(props) {

    const [isMobile, setIsMobile] = useState(false)
    const [posts, setPosts] = useState([])
    const [openPostDetails, setOpenPostDetails] = useState(false)
    const [openDeleteConfim, setOpenDeleteConfim] = useState(false)
    const [postDetail, setPostDetail] = useState({})
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchPostsByCategoryIds = () => {
        const sortBy = [
            "updated_at"
        ].join(",");
        setLoading(true)
        axios
            .get(`${gateway()}/api/posts/categories/`, {  // Use new endpoint for multiple categories
                params: {
                    category_ids: [], // Send multiple category IDs as comma-separated values
                    page: 1,
                    page_size: 20,
                    sort_by: sortBy,
                },
            })
            .then((res) => {

                setLoading(false)
                setPosts(res.data.results.filter((p) => p.releaseType === 1));

            })
            .catch((err) => {
                setLoading(false)
                console.error("fetch posts by category error : ", err);
            });
    };


    const fetchPostsCategories = () => {
        axios
            .get(`${gateway()}/api/categories/`)
            .then(res => {
                setCategories(res.data)
            })
            .catch(err => { console.error("posts categories info error : ", err); });
    };
    useEffect(() => {

        fetchPostsByCategoryIds();
        fetchPostsCategories()
        if (window) {
            if (window.innerWidth < 550) {
                setIsMobile(true)
            }
        }
    }, [])



    useEffect(() => {
        console.log("posts:", posts)
    }, [posts])
    return <div className='py-6'>
        <div className=' text-right w-full flex justify-start mb-2'>
            <p className='font-bold text-[14px] lg:text-[16px] py-1 border-b border-solid border-gray-800 w-fit'>
                جدید ترین مقاله ها
            </p>
        </div>
        <Swiper
            slidesPerView={isMobile ? 2 : 6}
            spaceBetween={30}
            // centeredSlides={true}
            // loop={true}

            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Navigation, Autoplay]}
            className="mySwiper text-center mt-50 mb-50 pr-60 pl-60 pb-40 pt-10"
        >
            {posts && posts.length > 0 ? posts.map((i) => {
                return <SwiperSlide>

                    <PostsListItem item={i} categories={categories} />

                </SwiperSlide>

            }) : null}

        </Swiper>
    </div>
}