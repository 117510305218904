import React, { createContext, useState, useContext } from "react";
import axios from "axios";
import gateway from "../api";
import { useToast } from "./ToastContext";
const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const showToast = useToast();

    const [cartDetail, setCartDetail] = useState(null)
    const [cartTotal, setCartTotal] = useState(null)
    const [error, setError] = useState(null)
    const [isCartLoading, setIsCartLoading] = useState(false)
    const token = localStorage.getItem('busiknowToken');

    const fetchCart = () => {

        const token = localStorage.getItem('busiknowToken');
        setIsCartLoading(true)

        axios
            .get(`${gateway()}/api/cart/view/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {

                setIsCartLoading(false)
                setCartDetail(res.data.cart);
                setCartTotal(res.data.total_price)
            })
            .catch(err => {
                setIsCartLoading(false); setError(err);
                console.error("fetch cart error : ", err);

            });
    };
    const addToCart = async (productId) => {
        setIsCartLoading(true)
        let itemExists = await checkIfExists(productId)
        if (itemExists) {
            showToast("این محصول قبلا به سبد خرید افزوده شده است", "", 3000, "error");
            fetchCart()
            setIsCartLoading(false)
        } else {
            try {

                axios.post(

                    `${gateway()}/api/cart/add/`,
                    { product_id: productId },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                ).then(res => {
                    setIsCartLoading(false);
                    showToast("محصول به سبد خرید افزوده شد", "", 3000, "success");
                    fetchCart()
                })

            } catch (error) {
                setIsCartLoading(false);
                console.error('Error adding to cart:', error);
            }
        }
    };
    const DeleteCartItem = async (id) => {

        setIsCartLoading(true)
        let itemExists = await checkIfExists(id)
        if (!itemExists) {
            showToast("محصول قبلا حذف شده است", "", 3000, "error")
            fetchCart()
            setIsCartLoading(false)
        } else {
            axios
                .delete(`${gateway()}/api/cart/remove/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {

                    setIsCartLoading(false)
                    fetchCart()
                })
                .catch(err => {
                    setIsCartLoading(false);
                    console.error("delete cart item error : ", err);
                    showToast("خطا در حذف محصول از سبد", "", 3000, "error")
                });
        }
    };


    const checkIfExists = async (id) => {

        const cartItems = await axios
            .get(`${gateway()}/api/cart/view/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                return res.data.cart
            })
            .catch(err => {

                console.log("check cart encountered some errors:", err)
            });
        if (cartItems !== null) {
            if (cartItems && cartItems.length > 0) {
                if (cartItems.find((i) => i.product_id === id)) {
                    return true;
                } else { return false; }
            } else {
                return false
            }
        }
    };
    const [isCartOpen, setCartOpen] = useState(false);
    const openTheCart = () => setCartOpen(true);
    const closeTheCart = () => setCartOpen(false);

    return (<>
        <CartContext.Provider value={{ isCartOpen, openTheCart, closeTheCart, fetchCart, cartDetail, cartTotal, addToCart, setIsCartLoading, isCartLoading, DeleteCartItem }}>
            {children}
        </CartContext.Provider>
    </>
    );
};