import { useEffect, useState, useRef } from "react";
import gateway from "../../api";
import axios from "axios";
import ProductsListItem from "../Molecules/ProductsListItem";
import { blackFilter, BlackMobileMenu, blackSort } from "../../icons-base64/icons";
import { ThosandsSeperator } from "../../Utilities/UtilityMethods";
import PopOverDropAt from "../Atoms/PopOverDropAt";
import DropItemAT from "../Atoms/DropItemAT";
import Pagination from "../Molecules/PaginationMol";
import LoadingAT from "../Atoms/LoadingAT";

export default function ProductsCategoryDetail() {

    const [products, setProducts] = useState(null);
    const [categories, setCategories] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [categoryName, setCategoryName] = useState(null);
    const [minPrice, setMinPrice] = useState(null);
    const [maxPrice, setMaxPrice] = useState(null);
    const [isCategoriesDropOpen, setIsCategoriesDropOpen] = useState(false)
    const [isPriceDropOpen, setIsPriceDropOpen] = useState(false)
    const [isDateDropOpen, setIsDateDropOpen] = useState(false)
    const [isLowestPriceFirst, setIsLowestPriceFirst] = useState(true)
    const [isNewestFirst, setIsnewestFirst] = useState(true)

    const [categoryLoading, setCategoryLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [dPCategories, setDPCategories] = useState([])
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [openCategories, setOpenCategories] = useState(false)
    const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(false);


    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(100)

    const pageSize = 10; // Set your page size here
    const productsRef = useRef(null);

    const [selectedCategories, setSelectedCategories] = useState([]);

    const toggleCategorySelection = (categoryId) => {
        setSelectedCategories((prevSelected) => {
            if (prevSelected.includes(categoryId)) {
                return prevSelected.filter(id => id !== categoryId); // Deselect category
            } else {
                return [...prevSelected, categoryId]; // Select category
            }
        });
    };

    const fetchProductsByCategoryId = (categoryId, filter) => {

        const sortBy = [
            isLowestPriceFirst ? "price" : "-price",
            !isNewestFirst ? "updated_at" : "-updated_at"
        ].join(",");
        setIsLoading(true)
        axios
            .get(`${gateway()}/api/digitalProducts/categories/`, { // Corrected URL
                params: {
                    category_ids: categoryId,
                    page: filter ? 1 : page,
                    page_size: pageSize,
                    min_price: minPrice ? parseInt(minPrice.replaceAll(",", "")) : 0,
                    max_price: maxPrice ? parseInt(maxPrice.replaceAll(",", "")) : 999999999999999,
                    sort_by: sortBy,
                },
            })
            .then((res) => {
                setIsLoading(false);
                setProducts(res.data.results.filter((p) => p.releaseType === 1));
                const totalPage = Math.ceil(res.data.count / pageSize);
                setPageCount(totalPage);
            })
            .catch((err) => {
                setIsLoading(false);
                console.error("fetch products by category error : ", err);
            });
        if (filter) {
            setPage(1)
        }
    };

    const fetchProductsCategories = (id) => {
        axios
            .get(`${gateway()}/api/dPCategories/`)
            .then(res => {

                setCategories(res.data)
                getCategoryName(res.data, id)
            })
            .catch(err => { console.error("fetch products categories error : ", err); });
    };

    function getCategoryName(categories, categoryId) {

        let categoryName
        if (categories && categories.length > 0) {
            if (categoryId) {
                categoryName = categories.find(e => e.id === parseInt(categoryId))?.name
                if (categoryName) {
                    setCategoryName(categoryName)
                }
            }
        }
    }
    function
        handleFetch(filter) {
        if (window) {
            let id = window.location.href.split("?")[1]
            fetchProductsByCategoryId(id, filter);
        }
    }






    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
        }
    };
    function dropTrigger(state) {
        if (state)
            setIsCategoriesDropOpen(true)
        else setIsCategoriesDropOpen(false)
    }
    function handlePriceDrop(state) {
        if (state)
            setIsPriceDropOpen(true)
        else setIsPriceDropOpen(false)
    }
    function handleDateDrop(state) {
        if (state)
            setIsDateDropOpen(true)
        else setIsDateDropOpen(false)
    }
    function selectTheCategory(cat) {
        setSelectedCategory(cat);
        setIsCategoriesDropOpen(false)
    }

    const getCategories = () => {
        setCategoryLoading(true)
        axios
            .get(`${gateway()}/api/dPCategories/`)
            .then(res => {
                setCategoryLoading(false)
                setOpenCategories(true)
                setDPCategories(res.data)
            })
            .catch(err => { setIsLoading(false); setError(error); console.error("get product categories error : ", err); });
    };

    useEffect(() => {
        getCategories()
        handleFetch();

    }, [])

    useEffect(() => {

        if (productsRef.current && page !== 1) {
            productsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [page, window]);
    useEffect(() => {
        if (window) {
            let id = window.location.href.split("?")[1]
            fetchProductsCategories(id)
        }
    }, [window])
    return (
        <>
            <div className="px-[20px] lg:px-[80px] pt-28 ">
                <div className="w-full flex gap-6">
                    {categoryName && <div className=" text-right font-bold text-lg flex-1">
                        {categoryName}
                    </div>}
                    <div className="">

                    </div>
                </div>

                <div className=" flex flex-col lg:flex-row py-4 lg:pt-10 gap-4">
                    <div className="w-full lg:w-1/6 lg:pt-2">
                        <div className="bg-white border border-solid border-gray-200 flex p-3 rounded-md cursor-pointer shadow-sm" onClick={() => setIsFiltersCollapsed(!isFiltersCollapsed)}>
                            <div className="w-1/2 flex justify-center"><img src={blackFilter} width={18} /></div>
                            <div className="w-1/2 flex justify-center"><img src={blackSort} width={18} /></div>

                        </div>
                        <div className={` bg-white rounded-md mt-1 shadow-sm border border-solid border-gray-200 w-full  py-4 px-4  transition-all duration-600 ease-in-out overflow-hidden ${isFiltersCollapsed ? 'max-h-0 opacity-0' : 'max-h-[500px] opacity-100'
                            }`}>
                            <div className="text-sm text-gray-800 text-right flex">
                                <img src={blackFilter} width={18} className="mx-1" />    فیلتر
                            </div>
                            <div className="text-xs mt-3 font-thin text-black/60">
                                <div className="flex gap-4 items-center mt-3">
                                    <div className="w-1/2 text-right">
                                        <div className="pr-1 pb-1 text-[9px]">قیمت از (تومان) </div> <input className="shadow-sm border border-solid border-gray-400 rounded-md py-[2px] px-[3px] w-full" value={minPrice && minPrice.length > 0 ? ThosandsSeperator(minPrice) : ""} onChange={(e) => setMinPrice(e.target.value)} />
                                    </div>
                                    <div className="w-1/2 text-right">
                                        <div><div className="pr-1 pb-1 text-[9px]">قیمت تا (تومان) </div> </div> <input className="shadow-sm border border-solid border-gray-400 rounded-md py-[2px] px-[3px] w-full" value={maxPrice && maxPrice.length > 0 ? ThosandsSeperator(maxPrice) : ""} onChange={(e) => setMaxPrice(e.target.value)} />
                                    </div>
                                </div>

                            </div>
                            <div className="text-sm text-gray-800 text-right mt-4 pt-3 border-t border-solid border-gray-300 flex">
                                <img src={blackSort} width={18} className="mx-1" />    ترتیب نمایش
                            </div>
                            <div className="text-xs mt-3 font-thin text-black/60">
                                <div className=" items-center mt-3">
                                    <div className="w-full text-right">
                                        <div className="pr-1 pb-1 text-[9px]">بر اساس قیمت</div>
                                        <PopOverDropAt
                                            onTriggerClick={handlePriceDrop}
                                            isOpen={isPriceDropOpen}
                                            DropTitle={
                                                <div>{isLowestPriceFirst === null ? "انتخاب نشده" : isLowestPriceFirst === true ? "کمترین قیمت" : "بیشترین قیمت"}</div>
                                            }
                                            containerClassName="!bg-[#fbfbfb] border-[#c8cbd0]"
                                        >
                                            <div>

                                                <div onClick={() => { setIsLowestPriceFirst(false); handlePriceDrop() }}> <DropItemAT >
                                                    بیشترین قیمت
                                                </DropItemAT>
                                                </div>
                                                <div onClick={() => { setIsLowestPriceFirst(true); handlePriceDrop() }}>
                                                    <DropItemAT >
                                                        کمترین قیمت
                                                    </DropItemAT>
                                                </div>

                                            </div>
                                        </PopOverDropAt>

                                    </div>
                                    <div className="w-full text-right mt-3">

                                        <div className="pr-1 pb-1 text-[9px]">بر اساس تاریخ</div>
                                        <PopOverDropAt
                                            onTriggerClick={handleDateDrop}
                                            isOpen={isDateDropOpen}
                                            DropTitle={
                                                <div>{isNewestFirst === null ? "انتخاب نشده" : isNewestFirst === true ? "جدید ترین" : "قدیمی ترین"}</div>
                                            }
                                            containerClassName="!bg-[#fbfbfb] border-[#c8cbd0]"
                                        >
                                            <div>

                                                <div onClick={() => { setIsnewestFirst(false); handleDateDrop() }}> <DropItemAT >
                                                    قدیمی ترین
                                                </DropItemAT>
                                                </div>
                                                <div onClick={() => { setIsnewestFirst(true); handleDateDrop() }}>
                                                    <DropItemAT >
                                                        جدید ترین
                                                    </DropItemAT>
                                                </div>

                                            </div>
                                        </PopOverDropAt>



                                    </div>
                                </div>
                            </div>
                            <div>
                                <button className="w-full text-xs mt-10 p-2 flex items-center justify-center shadow-md rounded-md bg-[#262626] text-white font-bold border-solid border-[#262626] border-2 hover:lg:text-black focus:text-black hover:lg:bg-white focus:bg-white transition-colors duration-200"
                                    onClick={() => handleFetch(true)} disabled={isLoading}>
                                    {isLoading ? <img src="/images/loading.svg" width={18} /> : "اعمال"}
                                </button>
                            </div>
                        </div>
                    </div>
                    {products && products.length > 0 ?
                        <div className="lg:w-5/6 grid grid-cols-2 gap-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 p-3 lg:p-0 lg:pt-2 mt-3 lg:mt-0" ref={productsRef}>
                            {products.map((product) => (
                                <a href={`/ProductDetail?${product.id}`}>  <div className="">   <ProductsListItem item={product} categories={categories} /></div></a>

                            ))}</div> : !isLoading &&
                        <div className="lg:w-5/6">
                            <div className="font-bold w-full h-full flex items-center justify-center text-2xl text-gray-800">موردی یافت نشد </div>
                        </div>
                    }
                </div>
                <div className="flex justify-center lg:justify-end">
                    {products && products.length > 0 && <div className="py-9 flex justify-center lg:w-5/6">
                        <div className="w-full lg:w-1/6"><Pagination currentPage={page} pageCount={pageCount} nextPage={() => setPage(page + 1)}
                            prevPage={() => setPage(page - 1)}
                        />
                        </div>
                    </div>}
                </div>
            </div>
            <LoadingAT isLoading={isLoading} />
        </>
    );
}


