import ModalMOL from "./Molecules/ModalMOL"
import Header from "./Organisms/Header"
import MobileHeader from "./Organisms/MobileHeader"
import { useEffect, useState } from "react"
import AccountForm from "./Organisms/AccountFormORG"
import Footer from "./Organisms/Footer"
import Cart from "./Organisms/Cart"
import { useModal } from "../Context/AccountModalContext"
const Layout = ({ children }) => {
    const { isModalOpen, openModal, closeModal } = useModal(); // Access context values





    return <><div className="" dir="rtl">
        <Header openAccountModal={openModal} />
        <MobileHeader openAccountModal={openModal} />
        <div className=" ">
            {children}
        </div>
        <Footer />

        <ModalMOL
            show={isModalOpen}
            size="sm"
            modalClassName="login-modal rounded-lg"
            heading="حساب کاربری"
            modalClosed={closeModal}
            hide={closeModal}
            position="top"
        >
            <AccountForm />
        </ModalMOL>
    </div>

    </>
}

export default Layout

