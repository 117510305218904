import { useEffect, useState, useRef } from "react";
import { useCart } from "../../Context/CartContext"
import CheckoutItem from "../Molecules/CheckoutItem";
import axios from "axios";
import gateway from "../../api";
import { BlackEmail, blackOutlinedEmail, blackOutlinedPhone, BlackPhone, blackUserOutlined } from "../../icons-base64/icons";
import { ThosandsSeperator } from "../../Utilities/UtilityMethods";
export default function Checkout() {
    const { DeleteCartItem, isCartLoading, cartDetail, fetchCart, cartTotal } = useCart();
    const [userInfo, setUserInfo] = useState(null);
    const [isMobile, setIsMobile] = useState(false)

    const payDivRef = useRef(null)
    const divRef = useRef(null)
    useEffect(() => {

        if (window) {
            if (window.innerWidth < 550) {
                setIsMobile(true)
            }
        }
    }, [window])
    useEffect(() => {
        fetchCart()
        RetrieveUserInfo()
    }, [])
    const RetrieveUserInfo = () => {
        let token = localStorage.getItem("busiknowToken")
        token &&
            axios.get(`${gateway()}/api/auth/user-info/`, {
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(response => {
                    setUserInfo(response.data.user_info);
                })
                .catch(error => {
                    console.error("user info error : ", error);

                });

    };

    const GetPaymentToken = () => {
        axios.post(`${gateway()}/api/orders/getPaymentToken/`, {
            amount: 1000,
            invoiceID: 'abc128909797',
            callbackURL: 'www.busiknow.com',
            TerminalID: 98929247
        })
            .then(response => {
                console.log('Payment token:', response.data);
            })
            .catch(error => {
                console.error('Error fetching token:', error);
            })
    };

    const [divHeight, setDivHeight] = useState(null)
    useEffect(() => {

        if (divRef.current !== null) {
            let d = divRef.current
            let divHeight = d.getBoundingClientRect().height
            let desiredArea = Math.round(divHeight - 100)
            setDivHeight(desiredArea)
        }
    })
    return <>
        <div className="px-[20px] lg:px-[80px] pt-28 h-full lg:h-screen flex flex-col lg:flex-row gap-5">

            <div className="flex flex-col gap-3 bg-gray-50 border-2 border-solid border-gray-100 w-full
             rounded-lg p-5 shadow-lg mb-7 lg:w-3/4 lg:overflow-y-auto relative min-h-[100px]">

                {isCartLoading && <div className="bg-black/30 absolute w-full h-full top-0 right-0 flex items-center 
                justify-center">
                    <img src="/images/loading.svg" width={60} />
                </div>}

                {cartDetail && cartDetail.length > 0 &&
                    cartDetail.map((c, index) => {
                        return <div className={``}><CheckoutItem item={c} last={cartDetail.length === index + 1} /></div>
                    })
                }


            </div>
            <div className=" bg-gray-50 border-2 border-solid border-gray-100 w-full rounded-lg p-5 shadow-lg mb-7 lg:w-1/4" ref={divRef}>
                <div style={{ height: divHeight !== null && !isMobile ? `${divHeight}px` : "unset" }}>
                    <div className="flex items-center gap-2 py-2 border-b border-solid border-gray-300">
                        <div className="opacity-80">
                            <img src={blackUserOutlined} width={25} />
                        </div>
                        <div className=" text-gray-600 text-[16px]">
                            {userInfo?.first_name} {userInfo?.last_name}
                        </div>
                    </div>
                    <div className="flex items-center gap-2 py-2 border-b border-solid border-gray-300">
                        <div className="opacity-80">
                            <img src={blackOutlinedEmail} width={25} />
                        </div>
                        <div className=" text-gray-600 text-[16px]">
                            {userInfo?.email}
                        </div>
                    </div>
                    <div className="flex items-center gap-2 py-2 border-b border-solid border-gray-300">
                        <div className="opacity-80">
                            <img src={blackOutlinedPhone} width={25} />
                        </div>
                        <div className=" text-gray-600 text-[16px]">
                            {userInfo?.mobile}
                        </div>
                    </div>
                    <div className="flex items-center gap-2 py-8 text-left ">
                        <div className="text-[16px] font-bold  flex-1 text-right">
                            مجموع سبد :
                        </div>
                        <div className="text-[16px] font-bold flex items-center gap-1 text-left">
                            {cartTotal && ThosandsSeperator(cartTotal)} <div className="text-xs text-gray-600 font-thin">
                                تومان
                            </div>
                        </div>
                    </div>

                </div>
                <div className=" w-full" ref={payDivRef}>
                    <button onClick={() => GetPaymentToken()} disabled={isCartLoading} className="w-full py-3 text-white bg-[#191919] rounded-lg hover:bg-white hover:text-black border-solid border-black hover:border ">
                        پرداخت
                    </button>
                </div>
            </div>
        </div>
    </>
}