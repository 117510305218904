import gateway from "../../api"
import { useEffect, useState } from 'react';
import { blackCalendar, blackChevonLeft, grayChevonLeft } from "../../icons-base64/icons";
import { convertToJalali } from "../../Utilities/UtilityMethods";



export default function PostsListItem(props) {

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {

        if (window) {
            if (window.innerWidth < 550) {
                setIsMobile(true)
            }
        }
    }, [])
    return <>

        <div className="atomic-card-primary rounded-[7px] " >
            <a className="flex  text-right p-3 mb-4 flex-col-reverse" href={`/PostDetail?${props.item.id}`}>
                <div className="w-full  px-3 flex  items-center flex-col-reverse">
                    <div className="flex-1 text-right flex flex-col items-start w-full my-2">
                        <div className="w-full atomic-heading-primary text-[12px] lg:text-[15px] text-black h-[50px] lg:h-[55px] ">
                            {props.item.title ? props.item.title.slice(0, 40) + (props.item.title.length > 40 ? " ..." : "") : ""}
                        </div>
                        <div className="w-full text-justify	 atomic-subHeading-primary text-[11px] my-2 h-[90px]" dir="rtl">
                            {props.item.summary ? isMobile ? props.item.summary.slice(0, 50) + (props.item.summary.length > 50 ? " ..." : "") : props.item.summary.slice(0, 120) + (props.item.summary.length > 120 ? " ..." : "") : ""}
                        </div>
                        <div className="pb-3">

                            <button className="text-[9px]  text-black flex items-center rounded-md py-1 px-2 border border-solid border-black/10 bg-gray-300" >
                                <img src={blackChevonLeft} className="opacity-80 mr-2" width={15} alt="next icon" />       <div>  جزئیات بیشتر</div>
                            </button>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-1 pt-2 border-t border-solid border-black/20 w-full">
                            <div className="flex items-center gap-1">
                                <img src={blackCalendar} width={14} className="opacity-60" alt="calendar icon" />
                                <div className="text-[9px] text-gray-500 pt-[1px]">{convertToJalali(props.item.updated_at)}</div>
                            </div>
                            <div className="flex-1 text-[9px] text-gray-500">{props.categories ? props.categories.find((e) => e.id === props.item.category)?.name : "---"}</div>
                        </div>
                    </div>


                </div>
                <div className="post-list-item w-full  rounded-md p-0">
                    <div className="post-list-item__image" style={{ backgroundImage: `url(${props.item.thumbnail})`, boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.4)' }}></div>
                </div>

            </a>
        </div>
    </>
}