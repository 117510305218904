import { useEffect, useState } from "react";
import gateway from "../../api";
import { RedExit, whiteDismissOutlined, whiteEditOutlined, whiteProduct, whiteSaveOutlined, WhiteShoppingBag } from "../../icons-base64/icons";
import axios from "axios";

export default function UserProfile() {
    const [viewComponent, setViewComponent] = useState("purchased");
    const [userInfo, setUserInfo] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [updatedInfo, setUpdatedInfo] = useState({
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
    });

    // Function to retrieve user info
    const RetrieveUserInfo = (token) => {
        return new Promise((resolve, reject) => {
            axios.get(`${gateway()}/api/auth/user-info/`, {
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(response => {
                    setUserInfo(response.data.user_info);
                    setUpdatedInfo(response.data.user_info); // Populate the edit form with current user data
                })
                .catch(error => {
                    console.error("user info error : ", error);
                    if (error.response) {
                        reject(error.response.data.message);
                    } else if (error.request) {
                        reject('No response received from the server');
                    } else {
                        reject('Error: ' + error.message);
                    }
                });
        });
    };

    // Function to update user info
    const updateUserInfo = (token) => {
        return new Promise((resolve, reject) => {
            axios.put(`${gateway()}/api/auth/user/edit/`, updatedInfo, {
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(response => {
                    setUserInfo(response.data); // Update displayed user info with the response data
                    setEditMode(false); // Exit edit mode
                    resolve(response.data);
                })
                .catch(error => {
                    console.error("update user info error : ", error);
                    if (error.response) {
                        reject(error.response.data.message);
                    } else if (error.request) {
                        reject('No response received from the server');
                    } else {
                        reject('Error: ' + error.message);
                    }
                });
        });
    };

    // Fetch user info on component mount
    useEffect(() => {
        let token = localStorage.getItem("busiknowToken");
        RetrieveUserInfo(token);
    }, []);

    // Handle input changes for editing user info
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUpdatedInfo({
            ...updatedInfo,
            [name]: value,
        });
    };

    // Handle form submission for updating user info
    const handleSubmit = (e) => {
        e.preventDefault();
        let token = localStorage.getItem("busiknowToken");
        updateUserInfo(token).catch((error) => {
            console.error("Error updating user info:", error);
        });
    };


    const Exit = () => {
        localStorage.removeItem("busiknowToken");
        window.location.href = "/"
    }
    return (
        <div className="px-[20px] lg:px-[80px] pt-28 min-h-screen">
            <div className="flex-col bg-gray-50 border-2 border-solid border-gray-100 w-full rounded-3xl p-5 shadow-lg mb-7">
                <div className="flex ">
                    <div className="w-1/3 lg:w-1/6 ">
                        <img src={"/images/user.png"} width={150} />
                    </div>
                    <div className="w-2/3 lg:w-5/6 flex-col flex items-start pr-4 pt-3">
                        {editMode ? (
                            <form onSubmit={handleSubmit} className="w-full">
                                <input
                                    type="text"
                                    name="first_name"
                                    value={updatedInfo.first_name}
                                    onChange={handleInputChange}
                                    placeholder="First Name"
                                    className="text-sm lg:text-base text-gray-600 border rounded p-2 w-full mb-2"
                                />
                                <input
                                    type="text"
                                    name="last_name"
                                    value={updatedInfo.last_name}
                                    onChange={handleInputChange}
                                    placeholder="Last Name"
                                    className="text-sm lg:text-base text-gray-600 border rounded p-2 w-full mb-2"
                                />
                                <input
                                    type="email"
                                    name="email"
                                    value={updatedInfo.email}
                                    onChange={handleInputChange}
                                    placeholder="Email"
                                    className="text-sm lg:text-base text-gray-600 border rounded p-2 w-full mb-2"
                                />
                                <input
                                    type="text"
                                    name="mobile"
                                    value={updatedInfo.mobile}
                                    onChange={handleInputChange}
                                    placeholder="Mobile"
                                    className="text-sm lg:text-base text-gray-600 border rounded p-2 w-full mb-2"
                                />
                                <div className="flex items-center justify-start gap-3">
                                    <button type="submit" className="bg-[#0d4b7a] text-white px-3 lg:px-6 py-2 rounded-md mt-4 text-xs flex gap-2 items-center text-center">
                                        <img src={whiteSaveOutlined} width={20} className="" />ذخیره
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setEditMode(false)}
                                        className="bg-gray-500  text-white px-3 lg:px-6 py-2 rounded-md mt-4 text-xs flex gap-2 items-center"
                                    >
                                        <img src={whiteDismissOutlined} width={20} className="" />انصراف
                                    </button>
                                </div>

                            </form>
                        ) : (<>
                            <div className="flex w-full">
                                <div className="w-4/6 flex flex-col justify-start">
                                    <div className="text-md lg:text-base text-gray-600 font-bold text-right">
                                        {userInfo && userInfo.first_name ? userInfo.first_name : null}
                                        {userInfo && userInfo.last_name ? userInfo.last_name : null}
                                    </div>
                                    <div className="text-xs lg:text-base text-gray-600 pt-5 text-right">
                                        {userInfo && userInfo.email ? userInfo.email : null}
                                    </div>
                                    <div className="text-xs lg:text-base text-gray-600 pt-5 text-right">
                                        {userInfo && userInfo.mobile ? userInfo.mobile : null}
                                    </div>

                                </div>

                                <div className="w-2/6 flex justify-end gap-1 p-2" >
                                    <div className="border-2 border-solid border-red-300 h-fit flex p-1 rounded-md cursor-pointer" onClick={() => Exit()}>
                                        <div className=""><img className="h-auto w-[20px]" src={RedExit} /></div>
                                        <div className="text-red-500 text-xs hidden lg:inline-block"> خروج از حساب کاربری </div>
                                    </div>
                                </div>
                            </div>


                        </>
                        )}
                    </div>

                </div>{!editMode ?
                    <div className="lg:pr-7">

                        <button
                            onClick={() => setEditMode(true)}
                            className="bg-[#0d4b7a] text-white px-6 py-2 rounded-md mt-4 text-xs flex gap-1 items-center justify-center w-full lg:w-[100px] text-center"
                        >
                            <img src={whiteEditOutlined} width={12} className="rotate-90" />    ویرایش
                        </button>
                    </div> : null}
            </div>
            <div className="flex form-tab bg-[#0d4b7a] mt-5 ">
                <div className="w-1/2 flex items-center justify-center">
                    <button className={`text-center text-xs text-white flex items-center justify-center w-full py-2 ${viewComponent === "purchased" ? "bg-white/20" : ""}`} onClick={() => setViewComponent("purchased")}>
                        <img src={whiteProduct} width={20} className="mx-1 mt-[3px]" />  محصولات خریداری شده
                    </button>
                </div>
                <div className="w-1/2 flex items-center justify-center">
                    <button className={`text-center text-xs text-white flex items-center justify-center w-full py-2 ${viewComponent === "unPayed" ? "bg-white/20" : ""}`} onClick={() => setViewComponent("unPayed")}>
                        <img src={WhiteShoppingBag} width={20} className="mx-1 " /> سفارشات پرداخت نشده
                    </button>
                </div>
            </div>
        </div>
    );
}
