import { useState, useRef, useEffect } from "react"
import gateway from "../../api"
import axios from "axios"
import { BlackShoppingBag, blackUserOutlined, WhiteShoppingBag, whiteUserOutlined } from "../../icons-base64/icons"
import Cart from "./Cart"
import { useLocation } from 'react-router-dom';
import DesktopMenu from "../Molecules/DesktopMenu"
import Search from "./Search"
import { isTokenValid } from "../../Utilities/UtilityApis"
import { useCart } from "../../Context/CartContext"


export default function Header(props) {
    const [info, setInfo] = useState(null)
    const [openCart, setOpenCart] = useState(false)

    const accRef = useRef(null);
    const location = useLocation();


    const isHomePage = location.pathname === '/';
    const isAboutUsPage = location.pathname === '/AboutUs';
    const isContactUsPage = location.pathname === '/ContactUs';
    const isPoliciesPage = location.pathname === '/Policies';
    const isPostPage = location.pathname === '/PostDetail';
    var isBannerPage = false;
    if (isPoliciesPage || isContactUsPage || isAboutUsPage || isHomePage || isPostPage) {
        isBannerPage = true
    }



    const handleAccountClick = async () => {
        const tokenIsValid = await isTokenValid();

        if (!tokenIsValid) {
            props.openAccountModal(); // Open the modal if the user is not logged in
        } else {
            window.location.href = "/UserProfile";
        }


    }
    const fetchHeaderInfo = () => {
        axios
            .get(`${gateway()}/api/business-info/header/`)
            .then(res => {

                setInfo(res.data)
            })
            .catch(err => { console.error("header info error : ", err); });
    };
    const handleCartOpen = () => {
        setOpenCart(true)
    }
    useEffect(() => {

        if (accRef.current !== null && window) {

            let Acc = accRef.current
            let x = Acc.getBoundingClientRect().x
            let y = Acc.getBoundingClientRect().y

        }
    }, [accRef, window])
    useEffect(() => {

        fetchHeaderInfo()
    }, [])

    const { openTheCart, isCartOpen, cartDetail } = useCart();


    useEffect(() => {

        if (document) {
            if (isCartOpen) {
                document.body.style.overflow = "hidden"
            } else {
                document.body.style.overflow = "auto"
            }
        }
    }, [isCartOpen])
    return <>
        <div className=" w-full absolute top-0 right-0 hidden lg:flex px-8 py-2 z-[10]">
            <div className={`items-center  border-b border-solid ${isBannerPage ? "border-white/40" : "border-black/40"}  flex w-full`}>
                <div className="min-h-[80px] min-w-[80px]">
                    <a href="/">   {info ? <img src={`${isBannerPage ? gateway() + info.logoLight : gateway() + info.logoDark}`} width="90" alt="busiknow.com logo" /> : null}</a>
                </div>
                <div className="flex-1 text-center pr-4">
                    <DesktopMenu isBannerPage={isBannerPage} />
                </div>
                <Search />
                <div className="pr-3" ref={accRef} onClick={() => handleAccountClick()}>
                    <img src={isBannerPage ? whiteUserOutlined : blackUserOutlined} width="27" alt="userIcon" className="cursor-pointer" />
                </div>
                <div className="pr-3 relative" ref={accRef} onClick={openTheCart}>
                    <img src={isBannerPage ? WhiteShoppingBag : BlackShoppingBag} width="27" alt="shopping bag icon" className="cursor-pointer" />
                    {cartDetail && cartDetail.length > 0 ? < div className="rounded-full  p-[0.5px] bg-red-600 text-white text-[6px] absolute right-[10px] top-0 w-[14px] h-[14px] flex items-center justify-center">
                        {cartDetail.length}
                    </div> : null}
                </div>
            </div>
        </div >
        <Cart isOpen={openCart} closeCart={() => setOpenCart(false)} />
    </>
}