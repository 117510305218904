import React, { createContext, useState, useContext, useEffect } from "react";
import { useToast } from "./ToastContext";
const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isNewLogin, setIsNewLogin] = useState(false);


    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const showToast = useToast();

    const LoginWelcome = () => {
        showToast("خوش آمدید", "", 3000);
        setIsNewLogin(true)
    }
    const LoginDone = () => {
        setIsNewLogin(false)
    }
    useEffect(() => {

        if (isNewLogin === true) {


        }
        setTimeout(() => {
            LoginDone()
        }, 100);
    }, [isNewLogin])
    return (<>
        <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, LoginWelcome, setIsNewLogin, isNewLogin, LoginDone }}>
            {children}
        </ModalContext.Provider>

    </>
    );
};