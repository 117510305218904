
export default function DesktopMenu(props) {


    return <>
        <div className={`flex items-center justify-start gap-5 text-sm ${props.isBannerPage ? "text-white" : "text-black"}`}>
            <a href="/">
                خانه
            </a>
            <a href="/PostsCategoryDetail?12">

                ایده سرمایه گذاری
            </a>
            <a href="/ProductsCategoryDetail?23">
                مطالعات بازار
            </a>

            <a href="/ProductsCategoryDetail?25">
                آمار و اطلاعات
            </a>
            <a href="/ProductsCategoryDetail?22">
                طرح توجیهی
            </a>
            <a href="/ProductsCategoryDetail?24">
                طرح کسب و کار
            </a>
            <a href="/AllPosts">
                مقالات
            </a>
            <a href="/AllProducts">
                محصولات
            </a>

            <a href="/AboutUs">
                درباره ما
            </a>
            <a href="/ContactUs">
                ارتباط با ما
            </a>

        </div>
    </>

}