import gateway from "../../api";
import { whiteplus, whiteTrash } from "../../icons-base64/icons";
import { ThosandsSeperator } from "../../Utilities/UtilityMethods";
import { useState } from "react";

import { useCart } from "../../Context/CartContext";
export default function CartItem(props) {
    let item = props.item
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const { DeleteCartItem } = useCart();
    return <>
        <a className="flex bg-white/15 p-2 rounded-lg gap-2 mb-2 backdrop-blur-[3px] border border-solid border-white/15" href={`/ProductDetail?${item.product_id}`}>
            <div className="w-1/5 flex items-center">
                <img src={`${gateway()}${item.product_mainPic}`} className="rounded-md" />
            </div>
            <div className="w-5/6 flex">
                <div className="w-5/6 flex flex-col justify-around">
                    <div className="text-sm  text-white/80">
                        {item.product_title}
                    </div>
                    <div className="text-xs font-bold mb-1  text-white">
                        {ThosandsSeperator(item.product_price)} تومان
                    </div>
                </div>
                <div className="w-1/6  rounded-md flex items-center justify-center">
                    <button onClick={() => DeleteCartItem(item.product_id)}>
                        <img src={whiteTrash} width={18} />
                    </button>
                </div>
            </div>
        </a>

    </>
}