import { useRef, useEffect, useState } from "react"
export default function PromotionSection() {
    const proRef = useRef(null);
    const [proHeight, setProHeight] = useState("0px")

    useEffect(() => {

        if (proRef.current !== null) {
            let pro = proRef.current
            let Height = pro.getBoundingClientRect().height
            setProHeight(`${Math.round(Height)}px`)

        }
    }, [proRef])

    return <div
        className="relative min-h-96 flex flex-col md:flex-row gap-8 items-center px-[20px] lg:px-[80px] py-9"
        style={{
            backgroundImage: `linear-gradient(to bottom, rgba(251,252,253,1), rgba(255,255,255,0.2), rgba(248,250,252,1)), url(images/hero.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}>
        <div className="p-5 w-full lg:w-fit rounded-2xl backdrop-blur-[2px] border-2 border-solid shadow-[0px_0px_6px_0px_rgba(0,_0,_0,_0.1)] border-gray-50 bg-white/60 h-[100%]" ref={proRef}>
            <div className="text-lg md:text-2xl text-gray-700 text-right font-bold">
                چرا بیزینو ؟
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + امکانسنجی، ارزیابی و تهیه طرح های توجیهی اقتصادی، فنی و مالی (FS, PFS)
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + مطالعات بازار داخلی و جهانی (Market Research)
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + مطالعات فرصت های سرمایه گذاری (داخلی و بین المللی)
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + تدوین Business Plan
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + ارائه خدمات مشاوره به سرمایه گذاران
            </div>

            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + ارائه ایده های سرمایه گذاری
            </div>

            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + مشاوره در زمینه برنامه ریزی و راه اندازی طرح های ایجادی و توسعه ای کشاورزی و صنعتی
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + تهیه دفترچه فنی و نقشه طرح های گلخانه ای
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + طراحی Site Plan
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + اجرای پروژه های کشاورزی در قالب قراردادهای EPCS (طراحی، تامین تجهیزات، احداث، بهره برداری و مشاوره تولید)
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + درخواست فرم سیتا برای طرح های کشاورزی
            </div>
            <div className="md:text-md  text-sm pt-2 text-gray-600 text-right">
                + مشاوره تولید محصولات گلخانه ای
            </div>
        </div>
        <div className="lg:flex justify-end flex-1 hidden ">
            <img src="/images/hero-people.jpg" className=" shadow-md" style={{ width: proHeight }} alt="busiknow office" />
        </div>
    </div>

}