import { createContext, useContext, useState } from "react";
import { greenSuccess, orangeWarning, redError } from "../icons-base64/icons";

// Create the context
const ToastContext = createContext();

// Toast Provider component
export const ToastProvider = ({ children }) => {
    const [toast, setToast] = useState({
        show: false,
        heading: "",
        subHeading: "",
        duration: 2000,
        mode: "success",
    });
    const showToast = (heading, subHeading = "", duration = 2000, mode = "success") => {
        setToast({ show: true, heading, subHeading, duration, mode });
        setTimeout(() => {
            setToast((prev) => ({ ...prev, show: false }));
        }, duration);
    };

    const borderRenderer = () => {
        switch (toast.mode) {
            case "success":
                return "#31cb00";
            case "warning":
                return "#ffb703";
            case "error":
                return "#ef233c";
            default:
                return "gray";
        }
    };
    const iconRenderer = () => {
        switch (toast.mode) {
            case "success":
                return greenSuccess;
            case "warning":
                return orangeWarning;
            case "error":
                return redError;
            default:
                return "";
        }
    };
    const bgRenderer = () => {
        switch (toast.mode) {
            case "success":
                return "#d7fff1";
            case "warning":
                return "#ffb950";
            case "error":
                return "#ffccd5";
            default:
                return "";
        }
    };
    return (
        <ToastContext.Provider value={showToast}>
            {children}
            <div
                className={`fixed z-[99] top-12 right-9 transition-all  duration-300 ease-in-out transform ${toast.show ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-5 pointer-events-none"
                    }`}
            >
                <div
                    className={`atomic-card-primary p-3 min-w-36 flex items-center gap-2 justify-center rounded-sm shadow-lg`}
                    style={{ borderColor: borderRenderer(), borderWidth: "0px 0px 4px 0px", borderStyle: "solid", backgroundColor: bgRenderer() }}
                >
                    <div>
                        <div className="text-sm text-gray-700 font-bold mb-1">{toast.heading}</div>
                        {toast.subHeading && <div className="text-[9px] text-gray-600">{toast.subHeading}</div>}
                    </div>
                    <img src={iconRenderer()} width={24} />

                </div>
            </div>
        </ToastContext.Provider>
    );
};

// Custom hook for using the toast
export const useToast = () => {
    return useContext(ToastContext);
};
