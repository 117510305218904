import RequestResetPassFormORG from "../Organisms/RequestResetPassFormORG";

export default function RequestResetPassword() {


    return <>
        <div className="px-[20px] lg:px-[80px] pt-28 md:pt-32 min-h-screen ">
            <div className="w-full text-right mb-8 text-lg font-bold text-gray-700 ">
                تغییر رمز عبور
            </div>
            <div className="flex flex-col md:flex-row justify-between items-center">
                <div className="w-full md:w-1/4 flex-col flex items-center bg-gray-50 border-2 border-solid border-gray-100 rounded-lg p-5 shadow-lg mb-7 h-fit">
                    <RequestResetPassFormORG />
                </div>
                <div className=" w-full md:w-1/3">
                    <img className="w-full " src="/images/reset-pass.png" />

                </div>
            </div>
        </div>
    </>
}