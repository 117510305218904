import { useState, useEffect } from 'react';
import ImageWithSubtitleMOL from '../Molecules/ImageWithSubtitleMOL';
import { whiteArticleOutlined, whiteIdeaOutlined, whiteInfoOutlined, whitePhoneOutlined, whiteLawOutlined, whiteProduct, whiteOutlinedMarketResearch, whiteOutlinedStatics, whitePlan, whiteBusinessPlan, whiteHouse } from '../../icons-base64/icons';

export default function MenuContent(props) {
    const [openCategories, setOpenCategories] = useState(false);
    const [location, setLocation] = useState('');
    useEffect(() => {
        if (window) {
            let url = window.location.pathname;
            let page;
            let a;
            if (url.includes('?')) {
                a = url.split('?')[0].split('/');
                page = url.split('?')[0].split('/')[a.length - 1];
            } else {
                a = url.split('/');
                page = url.split('/')[a.length - 1];
            }
            setLocation(page);
            if (page === 'productsCategories' || page === 'postsCategories') {
                setOpenCategories(true);
            }
        }
    }, []);
    return <div className="sidebar-content overflow-y-auto flex-grow lg:px-8">

        <a href="/">

            <div className={`${location !== 'pppp' ? '' : 'selected-menu'} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm pt-[1px]"
                    iconClassName="mr-1 ml-[3px]"
                    label="خانه"
                    iconSrc={whiteHouse}
                />
            </div>

        </a>
        <a href="/PostsCategoryDetail?12">

            <div className={`${location !== 'pppp' ? '' : 'selected-menu'} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm pt-[1px]"
                    iconClassName="mr-1 ml-[3px]"
                    label="ایده سرمایه‌گذاری"
                    iconSrc={whiteIdeaOutlined}
                />
            </div>

        </a>
        <a href="/ProductsCategoryDetail?23">

            <div className={`${location !== 'oooo' ? '' : 'selected-menu'} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm pt-[1px]"
                    iconClassName="mr-1 ml-[3px]"
                    label="مطالعات بازار"
                    iconSrc={whiteOutlinedMarketResearch}
                />
            </div>

        </a>
        <a href="/ProductsCategoryDetail?25">

            <div className={`${location !== 'uuuu' ? '' : 'selected-menu'} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm pt-[1px]"
                    iconClassName="mr-1 ml-[3px]"
                    label="آمار و اطلاعات"
                    iconSrc={whiteOutlinedStatics}
                />
            </div>

        </a>
        <a href="/ProductsCategoryDetail?22">

            <div className={`${location !== 'uuuu' ? '' : 'selected-menu'} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm pt-[1px]"
                    iconClassName="mr-1 ml-[3px]"
                    label="طرح توجیهی"
                    iconSrc={whitePlan}
                />
            </div>

        </a>    <a href="/ProductsCategoryDetail?24">

            <div className={`${location !== 'uuuu' ? '' : 'selected-menu'} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm pt-[1px]"
                    iconClassName="mr-1 ml-[3px]"
                    label="طرح کسب و کار"
                    iconSrc={whiteBusinessPlan}
                />
            </div>

        </a>
        <a href="/AllPosts">

            <div className={`${location !== 'AllPosts' ? '' : 'selected-menu'} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm pt-[1px]"
                    iconClassName="mr-1 ml-[3px]"
                    label=" مقالات"
                    iconSrc={whiteArticleOutlined}
                />
            </div>

        </a>
        <a href="/AllProducts">


            <div className={`${location !== 'AllProducts' ? '' : 'selected-menu'} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm pt-[1px]"
                    iconClassName="mr-1 ml-[3px]"
                    label=" محصولات"
                    iconSrc={whiteProduct}
                />
            </div>

        </a>
        <a href="/AboutUs">

            <div className={`${location === 'AboutUs' ? 'selected-menu' : ''} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm"
                    iconClassName="mr-1 ml-[3px]"
                    label="درباره ما"
                    iconSrc={whiteInfoOutlined}
                />
            </div>

        </a>


        <a href="/ContactUs">

            <div className={`${location === 'ContactUs' ? 'selected-menu' : ''} transition-all duration-200`}>
                <ImageWithSubtitleMOL
                    expanded={props.expanded}
                    iconWidth="24px"
                    headingClassName="m-auto white-color text-sm"
                    iconClassName="mr-1 ml-[3px]"
                    label="ارتباط با ما"
                    iconSrc={whitePhoneOutlined}
                />
            </div>

        </a>

    </div>
}