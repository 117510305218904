import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../Context/ToastContext";
import axios from "axios";
import gateway from "../../api";

export default function ResetPassword() {
    const location = useLocation(); // Access the query parameters from the URL
    const navigate = useNavigate();
    const showToast = useToast();

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // Parse query parameters to extract `uid` and `token`
    const queryParams = new URLSearchParams(location.search);
    const uid = queryParams.get("uid");
    const token = queryParams.get("token");

    async function resetPassword() {
        if (newPassword !== confirmPassword) {
            showToast("رمزهای عبور مطابقت ندارند", "", 3000, "error");
            return;
        }

        try {
            await axios.post(
                `${gateway()}/api/auth/password-reset-confirm/`,
                {
                    uid: uid,
                    token: token,
                    new_password: newPassword,
                },
                {
                    headers: { "Content-Type": "application/json" },
                }
            );

            showToast("رمز عبور با موفقیت تغییر کرد", "", 3000, "success");
            // navigate("/"); // Redirect to login page or homepage
        } catch (error) {

            console.error("Error resetting password:", error);
            showToast(
                "خطایی رخ داده است. لینک بازیابی ممکن است منقضی شده باشد",
                "",
                3000,
                "error"
            );
        }
    }

    return (
        <div className="px-[20px] lg:px-[80px] pt-28 md:pt-32 min-h-screen">
            <div className="w-full text-right mb-8 text-lg font-bold text-gray-700">
                تغییر رمز عبور
            </div>
            <div className="w-full md:w-1/4 flex flex-col items-center bg-gray-50 border-2 border-gray-100 rounded-lg p-5 shadow-lg">
                <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="رمز عبور جدید"
                    className="text-xs lg:text-base text-gray-600 border rounded-md py-1 px-2 w-full mb-5"
                />
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="تایید رمز عبور"
                    className="text-xs lg:text-base text-gray-600 border rounded-md py-1 px-2 w-full mb-5"
                />
                <button
                    className="w-full bg-[#00dfc0] rounded-md p-2 shadow-md border-[#009c86] border text-white"
                    onClick={resetPassword}
                >
                    تغییر رمز عبور
                </button>
            </div>
        </div>
    );
}
