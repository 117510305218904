import { useState, useEffect } from 'react';
import ImgAT from '../Atoms/ImgAT';
import ImageWithSubtitleMOL from '../Molecules/ImageWithSubtitleMOL';
import { whiteDoorOutlined, whiteUserOutlined } from '../../icons-base64/icons';
import { isTokenValid } from '../../Utilities/UtilityApis';

export default function AccountContent(props) {
    const [openCategories, setOpenCategories] = useState(false);
    const [tokenValidation, setTokenValidation] = useState(false);
    const [location, setLocation] = useState('');
    useEffect(() => {
        if (window) {
            let url = window.location.pathname;
            let page;
            let a;
            if (url.includes('?')) {
                a = url.split('?')[0].split('/');
                page = url.split('?')[0].split('/')[a.length - 1];
            } else {
                a = url.split('/');
                page = url.split('/')[a.length - 1];
            }
            setLocation(page);
            if (page === 'productsCategories' || page === 'postsCategories') {
                setOpenCategories(true);
            }
        }
    }, []);

    function openAccountModal() {
        props.openAccountModal()
    }

    const cheCkToken = async () => {

        const tokenIsValid = await isTokenValid();
        if (tokenIsValid === false) {
            setTokenValidation(false)
        } else {
            setTokenValidation(true)
        }
    }
    useEffect(() => {
        cheCkToken()
    }, [localStorage.getItem('busiknowToken')])
    return <div className="sidebar-content overflow-y-auto flex-grow lg:px-8 " >

        {
            tokenValidation ? <a href="/UserProfile"> <ImageWithSubtitleMOL
                expanded={true}
                iconWidth="24px"
                headingClassName="m-auto white-color text-sm"
                iconClassName="mr-1 ml-[3px]"
                label={
                    <div className="flex items-center">

                        پنل کاربری                    </div>
                }
                iconSrc={whiteUserOutlined}
            />
            </a>
                : <div onClick={() => openAccountModal()}><ImageWithSubtitleMOL
                    expanded={true}
                    iconWidth="27px"
                    headingClassName="m-auto white-color text-sm"
                    iconClassName="mr-1 ml-[3px]"
                    label={
                        <div className="flex items-center">

                            ورود / ثبت نام
                        </div>
                    }
                    iconSrc={whiteDoorOutlined}
                />
                </div>
        }




    </div>
}